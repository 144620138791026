<template>
  <div class="tw_process_group_list_item_wrapper" v-if="isVisible && isSerialItem && isNestItem" :class="[{ full: ['text_full', 'select_full', 'textarea'].includes(item.inputType) || item.key === 'exCustomsTotalQuantity' || item.key === 'imCustomsTotalQuantity' || item.key === 'transportation' || item.key === 'laytime' || groupName === 'logisticsViaGrp' || item.key.includes('departureFlightNo') || item.key.includes('coInvoiceSignerName'), infinite: addInfiniteItems[item.key], document_name: groupName === 'documentsGrp' && item.key.startsWith('documentName'), payment_document_terms_value: isPaymentDocumentTermsValue, no_label: isPaymentDocumentTermsValue && !item.key.includes('Original'), arrival_time: groupName === 'airGrp' && item.key.startsWith('arrivalTime'), expiry_date: groupName === 'bookingReferenceGrp' && item.key.startsWith('expiryDate') }, getClassName]">
    <!-- containerNo, sealNo等のセットで無制限追加可能な項目群 -->
    <TwProcessGroupListInfinite
      v-if="addInfiniteItems[item.key]"
      :item="item"
      :tableName="tableName"
      :groupName="groupName"
    />
    <div v-else class="tw_process_group_list_item">
      <div class="label">{{getLabel}}</div>
      <template v-if="isInActiveTab">
        <div v-if="hasModel && item.inputType === 'date'" class="value">{{model | utcOffsetDateFormat}}</div>
        <div v-else-if="item.inputType === 'dateTime' && item.compact && groupName === 'airGrp'" class="value">{{timeAndAirport}}</div>
        <div v-else-if="hasModel && item.inputType === 'dateTime'" class="value">{{model | utcOffsetDateTimeFormat}}</div>
        <div v-else-if="hasModel && item.inputType === 'systemDateTime'" class="value">{{model | dateTimeFormat}}</div>
        <div v-else-if="hasModel && item.inputType === 'time'" class="value">{{model | timeFormatShort}}</div>
        <div v-else-if="hasModel && item.inputType === 'dateNumber'" class="value">{{model | dateNumber}}</div>
        <div v-else-if="hasModel && item.inputType === 'dateTimeNumber'" class="value">{{model | dateTimeNumber}}</div>
        <div v-else-if="hasModel && isQuantity" class="value">{{formatQuantity}}</div>
        <div v-else-if="hasModel && isCurrency" class="value">{{formatCurrency}}</div>
        <div v-else-if="hasModel && item.code === 'BL_PICKUP'" class="value">{{BL_PICKUP}}</div>
        <div v-else-if="hasModel && item.code === 'DELIVERY_TERMS'" class="value">{{DELIVERY_TERMS}}</div>
        <div v-else-if="hasModel && item.code === 'FREE_CHARGE_FLG'" class="value">{{FREE_CHARGE_FLG}}</div>
        <div v-else-if="hasModel && item.code === 'INLAND_DELIVERY_FARE'" class="value">{{INLAND_DELIVERY_FARE}}</div>
        <div v-else-if="hasModel && item.code === 'PARTIAL_SHIPMENT_ALLOWED_NOT_ALLOWED'" class="value">{{PARTIAL_SHIPMENT_ALLOWED_NOT_ALLOWED}}</div>
        <div v-else-if="hasModel && item.code === 'PAYMENT_TERMS'" class="value">{{PAYMENT_TERMS}}</div>
        <div v-else-if="hasModel && item.code === 'TRANSHIPMENT_ALLOWED_NOT_ALLOWED'" class="value">{{TRANSHIPMENT_ALLOWED_NOT_ALLOWED}}</div>
        <div v-else-if="hasModel && item.code === 'PO_NO_UNIT'" class="value">{{PO_NO_UNIT}}</div>
        <div v-else-if="hasModel && item.code === 'SPECIAL_TERMS'" class="value">{{SPECIAL_TERMS}}</div>
        <div v-else-if="hasModel && item.code === 'TRANSPORT_INSURANCE_INLAND'" class="value">{{TRANSPORT_INSURANCE_INLAND}}</div>
        <div v-else-if="hasModel && item.code === 'BL_MENTION_AS_ARRANGED'" class="value">{{BL_MENTION_AS_ARRANGED}}</div>
        <div v-else-if="hasModel && item.code === 'EXPORT_IMPORT_LICENCE'" class="value">{{EXPORT_IMPORT_LICENCE}}</div>
        <div v-else-if="hasModel && item.code === 'TYPE_OF_SERVICE'" class="value">{{TYPE_OF_SERVICE}}</div>
        <div v-else-if="hasModel && item.code === 'TYPE_OF_SERVICE_FCL_LCL'" class="value">{{TYPE_OF_SERVICE_FCL_LCL}}</div>
        <div v-else-if="hasModel && item.code === 'TRANSPORTATION'" class="value">{{TRANSPORTATION}}</div>
        <div v-else-if="hasModel && item.code === 'BL_TO_BE_NEGOTIATED'" class="value">{{BL_TO_BE_NEGOTIATED}}</div>
        <div v-else-if="hasModel && item.code === 'FREIGHT_PREPAID_PAYABLE_AT'" class="value">{{FREIGHT_PREPAID_PAYABLE_AT}}</div>
        <div v-else-if="hasModel && item.code === 'TRANSPORTATION_SERVICE_LEVEL'" class="value">{{TRANSPORTATION_SERVICE_LEVEL}}</div>
        <div v-else-if="hasModel && item.code === 'BOOKING_TYPE'" class="value">{{BOOKING_TYPE}}</div>
        <div v-else-if="hasModel && item.code === 'TEMPERATURE_CONTROLLED'" class="value">{{TEMPERATURE_CONTROLLED}}</div>
        <div v-else-if="hasModel && item.code === 'DRYICE_FLAG'" class="value">{{DRYICE_FLAG}}</div>
        <div v-else-if="hasModel && item.code === 'REFERENCE_TYPE'" class="value">{{REFERENCE_TYPE}}</div>
        <div v-else-if="hasModel && item.code === 'AMS_FILER'" class="value">{{AMS_FILER}}</div>
        <div v-else-if="hasModel && item.inputType === 'grossWeight'" class="value">{{numberString}} {{WEIGHT_UNIT_BL}}</div>
        <div v-else-if="hasModel && item.inputType === 'grossVolume'" class="value">{{number}} {{VOLUME_UNIT}}</div>
        <div v-else-if="hasModel && item.code === 'BL_REFERENCE_TYPE'" class="value">{{BL_REFERENCE_TYPE}}</div>
        <div v-else-if="hasModel && item.code === 'CHARGE_TYPE'" class="value">{{CHARGE_TYPE}}</div>
        <div v-else-if="hasModel && item.code === 'BL_STATUS'" class="value">{{BL_STATUS}}</div>
        <div v-else-if="hasModel && item.code === 'BOOKING_STATUS'" class="value">{{BOOKING_STATUS}}</div>
        <div v-else-if="hasModel && item.code === 'TYPE_OF_MOVE'" class="value">{{TYPE_OF_MOVE}}</div>
        <div v-else-if="hasModel && item.code === 'CO_DOCUMENT_CATEGORY'" class="value">{{CO_DOCUMENT_CATEGORY}}</div>
        <div v-else-if="hasModel && item.code === 'CO_SETTLEMENT_METHOD'" class="value">{{CO_SETTLEMENT_METHOD}}</div>
        <div v-else-if="hasModel && item.code === 'CO_ITEM_CODE'" class="value">{{CO_ITEM_CODE}}</div>
        <div v-else-if="hasAnyModel && item.code === 'SYS_MST_PORT_AND_PLACE' && item.compact" class="value">{{portAndPlaceAndCountry}}</div>
        <div v-else-if="hasModel && item.code === 'SYS_MST_PORT_AND_PLACE'" class="value">{{portAndPlace}}</div>
        <div v-else-if="hasModel && item.code === 'SYS_MST_COUNTRY'" class="value">{{country}}</div>
        <!-- <div v-else-if="hasModel && item.code === 'SYS_MST_CONTAINER_TYPE'" class="value">{{SYS_MST_CONTAINER_TYPE}}</div> -->
        <div v-else-if="hasModel && item.code === 'SYS_MST_PACKAGE_TYPE'" class="value">{{SYS_MST_PACKAGE_TYPE}}</div>
        <div v-else-if="hasModel && item.code === 'CARGO_TRACKING_FLG'" class="value">{{CARGO_TRACKING_FLG}}</div>
        <!-- 決済書類条件の数値 -->
        <div v-else-if="isPaymentDocumentTermsValue" class="value">{{documentTermsValue}}</div>
        <div v-else-if="hasModel && item.inputType === 'number'" class="value">{{number}}</div>
        <div v-else-if="hasModel && item.inputType === 'numberString'" class="value">{{numberString}}</div>
        <div v-else-if="hasModel && item.inputType === 'textarea'" class="value" v-html="sanitize(model)" />
        <div v-else class="value">{{model}}</div>
      </template>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { BL_PICKUP_VARIABLES, DELIVERY_TERMS_VARIABLES, FREE_CHARGE_FLG_VARIABLES, INLAND_DELIVERY_FARE_VARIABLES, PARTIAL_SHIPMENT_ALLOWED_NOT_ALLOWED_VARIABLES, PAYMENT_TERMS_VARIABLES, TRANSHIPMENT_ALLOWED_NOT_ALLOWED_VARIABLES, PO_NO_UNIT_VARIABLES, SPECIAL_TERMS_FLG_VARIABLES, TRANSPORT_INSURANCE_INLAND_VARIABLES, BL_MENTION_AS_ARRANGED_VARIABLES, EXPORT_IMPORT_LICENCE_VARIABLES, TYPE_OF_SERVICE_VARIABLES, TYPE_OF_SERVICE_FCL_LCL_VARIABLES, TRANSPORTATION, TRANSPORTATION_VARIABLES, BL_TO_BE_NEGOTIATED_VARIABLES, FREIGHT_PREPAID_PAYABLE_AT_VARIABLES, TRANSPORTATION_SERVICE_LEVEL_VARIABLES, BOOKING_TYPE_VARIABLES, TEMPERATURE_CONTROLLED_VARIABLES, DRYICE_FLAG_VARIABLES, REFERENCE_TYPE_VARIABLES, AMS_FILER_VARIABLES, WEIGHT_UNIT_BL_VARIABLES, VOLUME_UNIT_VARIABLES, BL_REFERENCE_TYPE_VARIABLES, CHARGE_TYPE_VARIABLES, BL_STATUS_VARIABLES, BOOKING_STATUS_VARIABLES, TYPE_OF_MOVE_VARIABLES, CO_DOCUMENT_CATEGORY_VARIABLES, CO_SETTLEMENT_METHOD_VARIABLES, CO_ITEM_CODE_VARIABLES, CARGO_TRACKING_FLG_VARIABLES} from 'lib-tw-common';
import { formatQuantity, formatCurrency, formatNumber, formatNumberString } from '@/utils/searchUtil.js';
import { getKey, getSerialIndex, getItemIndex } from '@/utils/processedSerialItems';
import { normallyHiddenItem, checkExceptionallyItems, addInfiniteItems } from '@/dictionaries/addInfiniteItems';
import TwProcessGroupListInfinite from '@/components/molecules/TwProcessGroupListInfinite';

export default {
  name: 'TwProcessGroupListItem',
  props: {
    item: Object,
    tableName: String,
    groupName: String,
    serialIndexes: Object,
    serialItems: Object,
    isEditMode: Boolean,
    // プール用の配列からvalueを取得するための情報
    poolName: String,
    poolIndex: Number,
  },
  inject: ['s', 'tab', 'activeTab'],
  components: {
    TwProcessGroupListInfinite,
  },
  data() {
    return {
      addInfiniteItems: addInfiniteItems, // 配列に無制限追加可能な項目群
    };
  },
  computed: {
    model() {
      if (this.poolName && (this.poolIndex || this.poolIndex === 0)) {
        const targetRes = this.isEditMode ? this.s.cloneItems : this.s.res;
        if (this.poolName === 'lcpools') {
          return _.get(targetRes, `${this.tableName}.${this.poolName}[${this.poolIndex}].${this.groupName}.${this.item.key}`) || _.get(targetRes, `${this.tableName}.${this.poolName}[${this.poolIndex}].lcmeta.${this.item.key}`);
        }
        return _.get(targetRes, `${this.tableName}.${this.poolName}[${this.poolIndex}].${this.groupName}.${this.item.key}`);
      } else {
        if (this.isEditMode) {
          return _.get(this.s.cloneItems, `${this.tableName}.${this.groupName}.${this.item.key}`);
        }
        return _.get(this.s.res, `${this.tableName}.${this.groupName}.${this.item.key}`);
      }
    },
    hasModel() {
      return this.model === 0 || this.model;
    },
    hasAnyModel() {
      return this.model === 0 || this.model || _.get(this.s.res, this.item.groupTarget);
    },
    number() {
      if (_.isNaN(Number(this.model))) {
        return this.model;
      }
      return formatNumber(this.model, this.item.validationId);
    },
    numberString() {
      return formatNumberString(this.model);
    },
    documentTermsValue() {
      let preFix = '/ ';
      if (this.item.key.includes('Original')) {
        preFix = '';
      }
      if (!this.model && this.model !== 0) {
        return preFix + ' ';
      }
      return preFix + this.model;
    },
    SYS_MST_CURRENCY() {
      return this.$store.getters.getSysMstCurrency;
    },
    formatQuantity() {
      return formatQuantity(this.model, _.get(this.s.res, this.item.unitTarget), this.item.inputType === 'totalMeasurement' ? 6 : undefined);
    },
    formatCurrency() {
      const currencyUnit = _.get(this.s.res, this.item.unitTarget);
      const currencyId = _.get(_.find(this.SYS_MST_CURRENCY, {symbol: currencyUnit}), 'alphabeticCode');
      const currency = this.$store.getters.getCurrency(currencyId);
      if (currency) {
        return formatCurrency(this.model, currencyUnit, currency.minorUnit);
      }
      return formatCurrency(this.model, currencyUnit);
    },
    BL_PICKUP() {
      return _.get(_.find(BL_PICKUP_VARIABLES, {code: this.model}), 'label') || '';
    },
    SYS_MST_PACKAGE_TYPE() {
      return _.isString(this.model) ? this.model.split(',')[0] : null;
    },
    DELIVERY_TERMS() {
      const model = this.item.unitTarget ? _.get(this.s.res, this.item.unitTarget): this.model;
      return _.get(_.find(DELIVERY_TERMS_VARIABLES, {code: model}), 'label') || '';
    },
    FREE_CHARGE_FLG() {
      return _.get(_.find(FREE_CHARGE_FLG_VARIABLES, {code: this.model}), 'label') || '';
    },
    INLAND_DELIVERY_FARE() {
      return _.get(_.find(INLAND_DELIVERY_FARE_VARIABLES, {code: this.model}), 'label') || '';
    },
    PARTIAL_SHIPMENT_ALLOWED_NOT_ALLOWED() {
      return _.get(_.find(PARTIAL_SHIPMENT_ALLOWED_NOT_ALLOWED_VARIABLES, {code: this.model}), 'label') || '';
    },
    PAYMENT_TERMS() {
      const model = this.item.unitTarget ? _.get(this.s.res, this.item.unitTarget) : this.model;
      return _.get(_.find(PAYMENT_TERMS_VARIABLES, {code: model}), 'label') || '';
    },
    TRANSHIPMENT_ALLOWED_NOT_ALLOWED() {
      return _.get(_.find(TRANSHIPMENT_ALLOWED_NOT_ALLOWED_VARIABLES, {code: this.model}), 'label') || '';
    },
    PO_NO_UNIT() {
      return _.get(_.find(PO_NO_UNIT_VARIABLES, {code: this.model}), 'label') || '';
    },
    SPECIAL_TERMS() {
      return _.get(_.find(SPECIAL_TERMS_FLG_VARIABLES, {code: this.model}), 'label') || '';
    },
    TRANSPORT_INSURANCE_INLAND() {
      return _.get(_.find(TRANSPORT_INSURANCE_INLAND_VARIABLES, {code: this.model}), 'label') || '';
    },
    BL_MENTION_AS_ARRANGED() {
      return _.get(_.find(BL_MENTION_AS_ARRANGED_VARIABLES, {code: this.model}), 'label') || '';
    },
    EXPORT_IMPORT_LICENCE() {
      return _.get(_.find(EXPORT_IMPORT_LICENCE_VARIABLES, {code: this.model}), 'label') || '';
    },
    TYPE_OF_SERVICE() {
      return _.get(_.find(TYPE_OF_SERVICE_VARIABLES, {code: this.model}), 'label') || '';
    },
    TYPE_OF_SERVICE_FCL_LCL() {
      return _.get(_.find(TYPE_OF_SERVICE_FCL_LCL_VARIABLES, {code: this.model}), 'label') || '';
    },
    TRANSPORTATION() {
      return _.get(_.find(TRANSPORTATION_VARIABLES, {code: this.model}), 'label') || '';
    },
    BL_TO_BE_NEGOTIATED() {
      return _.get(_.find(BL_TO_BE_NEGOTIATED_VARIABLES, {code: this.model}), 'label') || '';
    },
    FREIGHT_PREPAID_PAYABLE_AT() {
      return _.get(_.find(FREIGHT_PREPAID_PAYABLE_AT_VARIABLES, {code: this.model}), 'label') || '';
    },
    TRANSPORTATION_SERVICE_LEVEL() {
      return _.get(_.find(TRANSPORTATION_SERVICE_LEVEL_VARIABLES, {code: this.model}), 'label') || '';
    },
    BOOKING_TYPE() {
      return _.get(_.find(BOOKING_TYPE_VARIABLES, {code: this.model}), 'label') || '';
    },
    TEMPERATURE_CONTROLLED() {
      return _.get(_.find(TEMPERATURE_CONTROLLED_VARIABLES, {code: this.model}), 'label') || '';
    },
    DRYICE_FLAG() {
      return _.get(_.find(DRYICE_FLAG_VARIABLES, {code: this.model}), 'label') || '';
    },
    REFERENCE_TYPE() {
      return _.get(_.find(REFERENCE_TYPE_VARIABLES, {code: this.model}), 'label') || '';
    },
    AMS_FILER() {
      return _.get(_.find(AMS_FILER_VARIABLES, {code: this.model}), 'label') || '';
    },
    WEIGHT_UNIT_BL() {
      return _.get(_.find(WEIGHT_UNIT_BL_VARIABLES, {code: this.getTargetValue()}), 'label') || '';
    },
    VOLUME_UNIT() {
      return _.get(_.find(VOLUME_UNIT_VARIABLES, {code: this.getTargetValue()}), 'label') || '';
    },
    BL_REFERENCE_TYPE() {
      return _.get(_.find(BL_REFERENCE_TYPE_VARIABLES, {code: this.model}), 'label') || '';
    },
    CHARGE_TYPE() {
      return _.get(_.find(CHARGE_TYPE_VARIABLES, {code: this.model}), 'label') || '';
    },
    BL_STATUS() {
      return _.get(_.find(BL_STATUS_VARIABLES, {code: this.model}), 'label') || '';
    },
    BOOKING_STATUS() {
      return _.get(_.find(BOOKING_STATUS_VARIABLES, {code: this.model}), 'label') || '';
    },
    TYPE_OF_MOVE() {
      return _.get(_.find(TYPE_OF_MOVE_VARIABLES, {code: this.model}), 'label') || '';
    },
    CO_DOCUMENT_CATEGORY() {
      return _.get(_.find(CO_DOCUMENT_CATEGORY_VARIABLES, {code: this.model}), 'label') || '';
    },
    CO_SETTLEMENT_METHOD() {
      return _.get(_.find(CO_SETTLEMENT_METHOD_VARIABLES, {code: this.model}), 'label') || '';
    },
    CO_ITEM_CODE() {
      return _.get(_.find(CO_ITEM_CODE_VARIABLES, {code: this.model}), 'label') || '';
    },
    CARGO_TRACKING_FLG() {
      const model = this.item.unitTarget ? _.get(this.s.res, this.item.unitTarget): this.model;
      return _.get(_.find(CARGO_TRACKING_FLG_VARIABLES, {code: model}), 'label') || '';
    },
    country() {
      return this.model;
      // const model = this.item.unitTarget ? _.get(this.s.res, this.item.unitTarget) : this.model;
      // return _.get(_.find(_.get(this, '$store.state.systemProperties.SYS_MST_COUNTRY') || [], {countryCd: model}), 'name') || '';
    },
    portAndPlace() {
      return this.model;
      // const model = this.item.unitTarget ? _.get(this.s.res, this.item.unitTarget) : this.model;
      // return _.get(_.find(_.get(this, '$store.state.systemProperties.SYS_MST_PORT_AND_PLACE') || [], {code: model}), 'name') || '';
    },
    portAndPlaceAndCountry() {
      const country = _.get(this.s.res, this.item.groupTarget);
      return `${this.model || ''} / ${country || ''}`;
    },
    timeAndAirport() {
      const time = this.$options.filters.utcOffsetDateTimeFormat(this.model);
      const airport = _.get(this.s.res, this.item.groupTarget) || '';
      if (!time && !airport) {
        return '';
      }
      return `${time} / ${airport}`;
    },
    isVisible() {
      // bookingInformationAirGrpの輸送サービスレベルは輸送手段がAirと未選択時のみ表示
      if (this.groupName === 'bookingInformationAirGrp' && this.item.key.includes('transportationServiceLevel') && this.hideTransportationServiceLevel()) {
        return false;
      }
      // 無制限追加可能な項目は非表示
      if (normallyHiddenItem.includes(this.item.key) && checkExceptionallyItems(this.tableName, this.groupName, this.item.key)) {
        return false;
      }
      return !this.item.hide && this.item.code !== 'SYS_MST_UNIT' && this.item.code !== 'SYS_MST_CURRENCY' && !(this.item.code === 'SYS_MST_COUNTRY' && this.item.compact) && !(this.groupName === 'airGrp' && this.item.code === 'SYS_MST_PORT_AND_PLACE' && this.item.compact)
    },
    isCurrency() {
      return ['currencyRef', 'currency', 'totalAmount', 'noCommercialValueAmount'].includes(this.item.inputType);
    },
    isQuantity() {
      return ['quantity', 'totalQuantity', 'totalNetWeight', 'totalGrossWeight', 'totalMeasurement', 'chargeableWeight', 'estimatedTotalGrossWeight', 'estimatedTotalMeasurement'].includes(this.item.inputType);
    },

    // 連番項目を可変にし追加可能にする対応--------------------------
    isSerialItem() {
      if ((this.serialIndex && this.itemIndex) || this.serialIndex === 0) return this.serialIndex >= this.itemIndex;
      else return true;
    },
    serialIndex() {
      if (this.serialIndexes && Object.keys(this.serialIndexes).length > 0) return getSerialIndex(this.serialIndexes, this.serialGroupName ? this.serialGroupName : this.item.key);
      else return false;
    },
    itemIndex() {
      if (this.serialItems && Object.keys(this.serialItems).length > 0) return getItemIndex(this.serialItems, this.item.key);
      else return false;
    },
    serialGroupName() {
      return _.get(this.serialItems[getKey(this.item.key)], 'groupName') || false;
    },
    isNestItem() {
      const nestGroupName = _.get(this.serialItems[getKey(this.item.key)], 'nestGroupName');
      if (nestGroupName && this.serialIndexes && Object.keys(this.serialIndexes).length > 0) {
        const subIndex = Number(getKey(this.item.key).replace(/[^0-9]/g, '')) - 1;
        return getSerialIndex(this.serialIndexes, nestGroupName) >= subIndex;
      } else return true;
    },
    // 決済書類条件の数値項目か
    isPaymentDocumentTermsValue() {
      if (this.groupName !== 'paymentDocumentTermsGrp') {
        return false;
      }
      if (/documentsRequired\d{1,2}Name/.test(this.item.key)) {
        return false;
      }
      return true;
    },
    // 決済書類条件のクラス名
    getClassName() {
      if (this.groupName !== 'paymentDocumentTermsGrp') {
        if (this.item.key === 'estimateOthers' || this.item.key === 'actualOthers') {
          return 'estimate_others';
        }
        if (this.item.key === 'estimateOtherCharge' || this.item.key === 'actualOtherCharge') {
          return 'estimate_other_charge';
        }
        if (this.item.key === 'expenses10Amount') {
          return 'wide';
        }
        return undefined;
      }
      if (this.item.key.includes('OfficeCopy') || this.item.key.includes('Officecopy')) {
        return 'office_copy';
      }

      if (this.item.key.includes('Copy') && _.get(_.find(this.$parent.group.children, {key: 'invOfficecopy'}), 'hide')) {
        return 'copy';
      }

      return undefined;
    },
    // 決済書類条件のラベル
    getLabel() {
      if (this.groupName === 'paymentDocumentTermsGrp' && this.item.key.includes('Original')) {
        if (_.get(_.find(this.$parent.group.children, {key: 'invOfficecopy'}), 'hide')) {
          return this.item.label + '/Copy';
        }
        return this.item.label + '/Copy/Office';
      }
      if (this.groupName === 'airGrp' && this.item.inputType === 'dateTime' && this.item.compact) {
        return this.item.label + '/Airport';
      }
      return this.item.label;
    },
    // アクティブなタブ配下にいるか
    isInActiveTab() {
      if (!this.tab && this.tab !== 0) {
        return true;
      }

      return _.get(this.activeTab, 'value') === this.tab;
    },
  },
  // mounted() {
  //   setTimeout(() => {
  //     this.setValue(this.tableName, this.groupName, this.item.key, 'value');
  //   }, 3000)
  // },
  methods: {
    sanitize(str) {
      return this.$options.filters.newlines(str);
    },
    // airGrpの輸送サービスレベルは輸送手段がAirと未選択時のみ表示
    hideTransportationServiceLevel() {
      const transportation = _.get(this.s, 'res.linkageTransportation.transferTermsGrp.transportation');
      if ((transportation || transportation === 0) && transportation !== TRANSPORTATION.AIRCRAFT) {
        return true;
      }
      return false;
    },
    getTargetValue() {
      const target = _.last(this.item.unitTarget.split('.'));
      return _.get(this.s, `res[${this.tableName}][${this.groupName}][${target}]`)
    },
  }
};
</script>

<style lang="scss" scoped>
  .tw_process_group_list_item_wrapper {
    width: 50%;
    min-width: 300px;
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
    &.full {
      width: 100%;
    }
    &.infinite {
      width: 100%;
    }
    // Document情報 情報群
    &.document_name {
      width: 100%;

      .tw_process_group_list_item {
        border-top: 1px solid $color_gray_300;
        padding-top: 12px;
      }

      &:first-child .tw_process_group_list_item {
        border-top: 0;
        padding-top: 0;
      }
    }

    // 決済書類条件 情報群
    &.payment_document_terms_value {
      width: 147px;
      min-width: 0;

      .tw_process_group_list_item {
        padding-right: 0;
      }

      &.no_label {
        width: 30px;

        .label {
          display: none;
        }
      }

      &.office_copy {
        width: calc(50% - 30px - 150px);
      }

      &.copy {
        width: calc(50% - 147px);
      }
    }

    // 航空 情報群、Bookingリファレンス情報群
    &.arrival_time, &.expiry_date {
      // width: 100%;

      .tw_process_group_list_item {
        position: relative;
        overflow-x: visible;
        padding-bottom: 12px;

        &:after {
          position: absolute;
          display: block;
          width: 200%;
          right: 0;
          bottom: 0;
          border-bottom: 1px solid $color_gray_300;
          content: '';
        }
      }

      &:last-child .tw_process_group_list_item {
        padding-bottom: 0;

        &:after {
          content: none;
        }
      }
    }

    // 見積もり運賃
    &.estimate_others {
      .tw_process_group_list_item {
        position: relative;
        overflow-x: visible;
        padding-bottom: 12px;

        &:after {
          position: absolute;
          display: block;
          width: 200%;
          right: 0;
          bottom: 0;
          border-bottom: 1px solid $color_gray_300;
          content: '';
        }
      }
    }

    // 見積もり運賃
    &.estimate_other_charge {
      width: 100%;
      .tw_process_group_list_item {
        position: relative;
        overflow-x: visible;
        padding-bottom: 12px;

        &:after {
          position: absolute;
          display: block;
          width: 100%;
          right: 0;
          bottom: 0;
          border-bottom: 1px solid $color_gray_300;
          content: '';
        }
      }
    }

    // FY2022V2PH3-775 env1【プロセス詳細画面】Expenses 10 Amountの項目名が10で折り返されている為、一行になるよう項目幅を広げたい
    &.wide .tw_process_group_list_item .label {
      width: 119px;
      margin-right: 7px;
    }
  }
  .tw_process_group_list_item {
    // width: 50%;
    // min-width: 300px;
    // margin-bottom: 12px;
    display: flex;
    padding-right: 16px;

    &.full {
      width: 100%;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .label {
      width: 118px;
      font-size: 12px;
      line-height: 18px;
      color: #9191A4;
      margin-right: 8px;
      vertical-align: top;
      padding-top: 2px;
    }

    .value {
      font-size: 14px;
      line-height: 20px;
      color: #3E3A39;
      vertical-align: top;
      flex: 1;
    }
  }
</style>
