import _ from 'lodash';

/**
 * 連番項目の制御 ※※ ↓↓下部にある、連番項目の識別インデックスに定義すること ※※
 * remarks1、remarks2 等の連番項目を任意で追加可能にします
 * paymentTerms1、paymentRemarks1 等の、一つのグループ内に、さらに複数の連番項目グループがある場合も想定
 * NOTE:
 *    最初はRemarks1のみの表示
 * 		+押下で１つ増えて入力できる。
 * 		次回、中身が入っている一番最後のところまで表示。
 * 		+押下でRemarks2表示していても中身が空なら次回表示されない。+押下でRemarks2から
 * 		Remarks2は空でRemarks3に入力があった場合、次回はRemarks3まで表示
 * 		Remarks10まで表示したら+は非表示
 * 		詳細表示側も中身が入っている一番最後のところまで表示。
 */

// TwProcessGroupList 親側で利用-----------------------------------------------------------------------
/**
 * 可変項目が含まれるグループの、
 * 連番リストにvalueを追加しを返却します
 * @return {Object}
 */
export const getSerialGrpItems = (obj, name = '') => {
  if (serialGrpName.includes(name)) {
    // serialGrpName、serialGrpItems に設定して手動（ハードコード）で対応する場合
    const result = _.forEach(serialGrpItems[name], items => {
      _.forEach(items.items, item => {
        if (obj[item.key]) item.value = obj[item.key]
        else if (obj[item.key] === 0) item.value = 0
        else item.value = null
      })
    })
    return result
  } else return {}
}

/**
 * 連番リスト内のvalueがfalsyではない、
 * 最後のindex番号を返却します
 * @return {Object}
 */
export const getSerialGrpIndex = (obj) => {
  let indexes = {}
  _.forEach(obj, (item, key) => {
    const index = _.findLastIndex(item.items, (o) => { return o.value || o.value === 0 })
    if (item.nestGroupName) {
      const subIndex = Number(key.replace(/[^0-9]/g, '')) - 1
      indexes[key] = index === -1 ? 0 : index
      if (index > 0 && subIndex > indexes[item.nestGroupName]) {
        indexes[item.nestGroupName] = subIndex
      }
    } else if (item.groupName && index > indexes[item.groupName]) indexes[item.groupName] = index === -1 ? 0 : index
    else indexes[key] = index === -1 ? 0 : index
  })
  return indexes
}

// TwProcessGroupListItem 子側で利用-----------------------------------------------------------------------
/**
 * 子側から見た、自分の所属する連番項目グループの、中身が入っている一番最後のindexを返却します
 * NOTE: getSerialGrpIndex()で、生成したindex番号を取り出す
 * @return {Number}
 */
export const getSerialIndex = (indexes, name) => {
  const key = getKey(name)
  const index = _.get(indexes, `${key}`)
  return index || index === 0 ? index : false
}

/**
 * 子側から見た、自分の所属する連番項目グループ内の、子自身のindexを返却します
 * NOTE: getSerialGrpItems()で、生成した連番リストと子自身のkey名からindex番号を取り出す
 * @return {Number}
 */
export const getItemIndex = (list, name) => {
  const key = getKey(name)
  const value = _.get(list, `${key}`)
  if (!value) return false
  const index = _.findIndex(list[key].items, ['key', name])
  return index || index === 0 ? index : false
}

/**
 * 自分の所属する連番項目グループの、最大index番号を返却します
 * @return {Number}
 */
export const getMaxIndex = (list, name) => {
  const key = getKey(name)
  const value = _.get(list, `${key}`)
  if (!value) return false
  const index = list[key].items ? list[key].items.length - 1 : false
  return index
}

/**
 * 連番項目名の整形
 * @return {String}
 */
export const getKey = (name) => {
  if (name.includes('_')) return name.length ? name.substring(0, name.indexOf('_')) : ''
  else return name.length ? name.replace(/[0-9()_]/g, '') : ''
}

// TwProcessGroupListPreviewGoodsLinePackingItem で利用-----------------------------------------------------------------------
/**
 * 子側から見た、自分の所属する連番項目グループの、中身が入っている一番最後のindexを返却します
 * @return {Number}
 */
export const getGoodsSerialIndex = (obj) => {
  let array = []
  _.forEach(Object.keys(obj), key => {
    let item = {};
    item.key = key;
    item.value = obj[key];
    array.push(item);
  })
  const index = _.findLastIndex(array, (o) => { return o.value || o.value === 0 });
  return index === -1 ? 0 : index;
}

// 連番項目の識別インデックス---------------------------------------------------------------------------
// NOTE: serialGrpName、serialGrpItems に登録すると可変連番項目になります
/**
 * 可変する連番項目のあるグループ名を追加（variableName）※随時追加
 */
export const serialGrpName = [
  'remarksGrp',
  'paymentTermsGrp',
  'bankInformationGrp',
  'conditionsGrp',
  'transportConditions',
  'packingTermGrp',
  'packingTermsGrp',
  'cosInspectionGrp',
  'paymentDocumentsTermsGrp',
  'paymentDocumentTermsGrp',
  // 'quantityGrp',
  // 'netGrossMeasurementGrp',
  'expensesGrp',
  'contractTermsGrp',
  'transferTermsGrp',
  'inspectionTermsGrp',
  'commercialInvoiceGrp',
  'documentsGrp',
  // 'logisticsViaGrp',
  'airGrp',
  'bookingReferenceGrp',
  'blRequestGrp',
  'blSwbAwbTermsGrp',
  'containerInfoGrp', // 配列で受け渡しする項目
  'ipreqInformationGrp',
  'ipreqSpecialConditionsGrp',
  'cargoTrackingGrp',
  // AddressがあるGrp ↓↓---------
  // 'sellerGrp',
  // 'buyerGrp',
  // 'makerGrp',
  // 'vendorGrp',
  // 'endUserGrp',
  // 'customsAgentGrp',
  // 'forwarderGrp',
  // 'forwarderShipmentGrp',
  // 'forwarderNvoccGrp',
  // 'fwdImCntGrp',
  // 'impForwarderNvoccGrp',
  // 'forwarderIpGrp',
  // 'airGrp',
  // 'airfreightGrp',
  // 'charterGrp',
  // 'charteringBrokerGrp',
  // 'shipperGrp',
  // 'consigneeGrp',
  // 'notify1Grp',
  // 'notify2Grp',
  // 'carrierGrp',
  // 'salesAgentGrp',
  // 'purchaseAgentGrp',
]

/**
 * 可変する連番項目の割り当て表 ※随時追加
 * テーブルグループ → 各連番グループ → 連番項目
 * ※ paymentTerms、paymentAmount等の動きを連動させる必要がある項目は、groupNameに基準となるグループ名を設定してください
 * ※ さらに、入れ子になって動きを連動させる必要がある項目は、nestGroupNameに基準となるグループ名を設定します（documentRemarks1_1等のハイフンで区切られたもの）
 * ※ 入れ子になっていて複数同時に項目を増やす場合は、連番追加ボタンが■のデザインとなるため、groupButton: true を設定してください
*/
const serialGrpItems = {
  // remarksGrp ----------------------------
  remarksGrp: {
    remarks: {
      groupName: '',
      items: [
        { key: 'remarks1' },
        { key: 'remarks2' },
        { key: 'remarks3' },
        { key: 'remarks4' },
        { key: 'remarks5' },
        { key: 'remarks6' },
        { key: 'remarks7' },
        { key: 'remarks8' },
        { key: 'remarks9' },
        { key: 'remarks10' },
      ]
    },
  },
  // paymentTermsGrp ----------------------------
  paymentTermsGrp: {
    paymentTerms: {
      groupName: '',
      items: [
        { key: 'paymentTerms1' },
        { key: 'paymentTerms2' },
        { key: 'paymentTerms3' },
      ],
    },
    paymentAmount: {
      groupName: 'paymentTerms',
      items: [
        { key: 'paymentAmount1' },
        { key: 'paymentAmount2' },
        { key: 'paymentAmount3' },
      ],
    },
    paymentRemarks: {
      groupName: '',
      items: [
        { key: 'paymentRemarks1' },
        { key: 'paymentRemarks2' },
        { key: 'paymentRemarks3' },
        { key: 'paymentRemarks4' },
        { key: 'paymentRemarks5' },
        { key: 'paymentRemarks6' },
        { key: 'paymentRemarks7' },
        { key: 'paymentRemarks8' },
      ],
    },
  },
  // bankInformationGrp ----------------------------
  bankInformationGrp: {
    bkInformation: {
      groupName: '',
      items: [
        { key: 'bkInformation1' },
        { key: 'bkInformation2' },
        { key: 'bkInformation3' },
        { key: 'bkInformation4' },
        { key: 'bkInformation5' },
      ],
    },
  },
  // conditionsGrp ----------------------------
  conditionsGrp: {
    additionalConditions: {
      groupName: '',
      items: [
        { key: 'additionalConditions1' },
        { key: 'additionalConditions2' },
        { key: 'additionalConditions3' },
        { key: 'additionalConditions4' },
        { key: 'additionalConditions5' },
        { key: 'additionalConditions6' },
        { key: 'additionalConditions7' },
        { key: 'additionalConditions8' },
        { key: 'additionalConditions9' },
        { key: 'additionalConditions10' },
      ],
    },
  },
  // transportConditions ----------------------------
  transportConditions: {
    cosShipmentRemarks: {
      groupName: '',
      items: [
        { key: 'cosShipmentRemarks1' },
        { key: 'cosShipmentRemarks2' },
        { key: 'cosShipmentRemarks3' },
      ],
    },
  },
  // packingTermGrp ----------------------------
  packingTermGrp: {
    packingTerms: {
      groupName: '',
      items: [
        { key: 'packingTerms1' },
        { key: 'packingTerms2' },
        { key: 'packingTerms3' },
      ],
    },
  },
  // packingTermsGrp ----------------------------
  packingTermsGrp: {
    packingTerms: {
      groupName: '',
      items: [
        { key: 'packingTerms1' },
        { key: 'packingTerms2' },
        { key: 'packingTerms3' },
      ],
    },
  },
  // cosInspectionGrp ----------------------------
  cosInspectionGrp: {
    cosInspection: {
      groupName: '',
      items: [
        { key: 'cosInspection1' },
        { key: 'cosInspection2' },
        { key: 'cosInspection3' },
      ],
    },
  },
  // paymentDocumentsTermsGrp ----------------------------
  paymentDocumentsTermsGrp: {
    documentsRequiredName: {
      groupName: '',
      items: [
        { key: 'documentsRequired1Name' },
        { key: 'documentsRequired2Name' },
        { key: 'documentsRequired3Name' },
        { key: 'documentsRequired4Name' },
        { key: 'documentsRequired5Name' },
      ],
    },
    documentsRequiredOriginal: {
      groupName: 'documentsRequiredName',
      items: [
        { key: 'documentsRequired1Original' },
        { key: 'documentsRequired2Original' },
        { key: 'documentsRequired3Original' },
        { key: 'documentsRequired4Original' },
        { key: 'documentsRequired5Original' },
      ],
    },
    documentsRequiredCopy: {
      groupName: 'documentsRequiredName',
      items: [
        { key: 'documentsRequired1Copy' },
        { key: 'documentsRequired2Copy' },
        { key: 'documentsRequired3Copy' },
        { key: 'documentsRequired4Copy' },
        { key: 'documentsRequired5Copy' },
      ],
    },
  },
  // paymentDocumentTermsGrp ----------------------------
  paymentDocumentTermsGrp: {
    documentsRequiredName: {
      groupName: '',
      items: [
        { key: 'documentsRequired1Name' },
        { key: 'documentsRequired2Name' },
        { key: 'documentsRequired3Name' },
        { key: 'documentsRequired4Name' },
        { key: 'documentsRequired5Name' },
        { key: 'documentsRequired6Name' },
        { key: 'documentsRequired7Name' },
        { key: 'documentsRequired8Name' },
        { key: 'documentsRequired9Name' },
        { key: 'documentsRequired10Name' },
      ],
    },
    documentsRequiredOriginal: {
      groupName: 'documentsRequiredName',
      items:[
        { key: 'documentsRequired1Original' },
        { key: 'documentsRequired2Original' },
        { key: 'documentsRequired3Original' },
        { key: 'documentsRequired4Original' },
        { key: 'documentsRequired5Original' },
        { key: 'documentsRequired6Original' },
        { key: 'documentsRequired7Original' },
        { key: 'documentsRequired8Original' },
        { key: 'documentsRequired9Original' },
        { key: 'documentsRequired10Original' },
      ],
    },
    documentsRequiredCopy: {
      groupName: 'documentsRequiredName',
      items: [
        { key: 'documentsRequired1Copy' },
        { key: 'documentsRequired2Copy' },
        { key: 'documentsRequired3Copy' },
        { key: 'documentsRequired4Copy' },
        { key: 'documentsRequired5Copy' },
        { key: 'documentsRequired6Copy' },
        { key: 'documentsRequired7Copy' },
        { key: 'documentsRequired8Copy' },
        { key: 'documentsRequired9Copy' },
        { key: 'documentsRequired10Copy' },
      ],
    },
    documentsRequiredOfficeCopy: {
      groupName: 'documentsRequiredName',
      items: [
        { key: 'documentsRequired1OfficeCopy' },
        { key: 'documentsRequired2OfficeCopy' },
        { key: 'documentsRequired3OfficeCopy' },
        { key: 'documentsRequired4OfficeCopy' },
        { key: 'documentsRequired5OfficeCopy' },
        { key: 'documentsRequired6OfficeCopy' },
        { key: 'documentsRequired7OfficeCopy' },
        { key: 'documentsRequired8OfficeCopy' },
        { key: 'documentsRequired9OfficeCopy' },
        { key: 'documentsRequired10OfficeCopy' },
      ],
    },
  },
  // quantityGrp ----------------------------
  // quantityGrp: {
    // quantity: {
    //   groupName: '',
    //   items: [
    //     { key: 'quantity1' },
    //     { key: 'quantity2' },
    //   ],
    // },
    // unitId: {
    //   groupName: '',
    //   items: [
    //     { key: 'unitId1' },
    //     { key: 'unitId2' },
    //   ],
    // },
    // unit: {
    //   groupName: '',
    //   items: [
    //     { key: 'unit1' },
    //     { key: 'unit2' },
    //   ],
    // },
    // unitRoundDigit: {
    //   groupName: '',
    //   items: [
    //     { key: 'unitRoundDigit1' },
    //     { key: 'unitRoundDigit2' },
    //   ],
    // },
    // unitRoundType: {
    //   groupName: 'unitRoundDigit',
    //   items: [
    //     { key: 'unitRoundType1' },
    //     { key: 'unitRoundType2' },
    //   ],
    // },
  // },
  // netGrossMeasurementGrp ----------------------------
  // NOTE: 以下チケット対応で、フロント側ではshippingMark2~10は完全に非表示とする
  // https://tradewaltz.backlog.com/view/FY2023V2PH7-427
  // netGrossMeasurementGrp: {
  //   shippingMark: {
  //     groupName: '',
  //     items: [
  //       { key: 'shippingMark1' },
  //       { key: 'shippingMark2' },
  //       { key: 'shippingMark3' },
  //       { key: 'shippingMark4' },
  //       { key: 'shippingMark5' },
  //       { key: 'shippingMark6' },
  //       { key: 'shippingMark7' },
  //       { key: 'shippingMark8' },
  //       { key: 'shippingMark9' },
  //       { key: 'shippingMark10' },
  //     ],
  //   },
  // },
  // // expensesGrp ----------------------------
  expensesGrp: {
    expensesTitle: {
      groupName: '',
      items: [
        { key: 'expenses1Title' },
        { key: 'expenses2Title' },
        { key: 'expenses3Title' },
        { key: 'expenses4Title' },
        { key: 'expenses5Title' },
        { key: 'expenses6Title' },
        { key: 'expenses7Title' },
        { key: 'expenses8Title' },
        { key: 'expenses9Title' },
        { key: 'expenses10Title' },
      ],
    },
    expensesAmount: {
      groupName: 'expensesTitle',
      items: [
        { key: 'expenses1Amount' },
        { key: 'expenses2Amount' },
        { key: 'expenses3Amount' },
        { key: 'expenses4Amount' },
        { key: 'expenses5Amount' },
        { key: 'expenses6Amount' },
        { key: 'expenses7Amount' },
        { key: 'expenses8Amount' },
        { key: 'expenses9Amount' },
        { key: 'expenses10Amount' },
      ],
    },
    expensesCurrency: {
      groupName: 'expensesTitle',
      items: [
        { key: 'expenses1Currency' },
        { key: 'expenses2Currency' },
        { key: 'expenses3Currency' },
        { key: 'expenses4Currency' },
        { key: 'expenses5Currency' },
        { key: 'expenses6Currency' },
        { key: 'expenses7Currency' },
        { key: 'expenses8Currency' },
        { key: 'expenses9Currency' },
        { key: 'expenses10Currency' },
      ],
    },
    expensesCurrencyId: {
      groupName: 'expensesTitle',
      items: [
        { key: 'expenses1CurrencyId' },
        { key: 'expenses2CurrencyId' },
        { key: 'expenses3CurrencyId' },
        { key: 'expenses4CurrencyId' },
        { key: 'expenses5CurrencyId' },
        { key: 'expenses6CurrencyId' },
        { key: 'expenses7CurrencyId' },
        { key: 'expenses8CurrencyId' },
        { key: 'expenses9CurrencyId' },
        { key: 'expenses10CurrencyId' },
      ],
    },
  },
  // contractTermsGrp ----------------------------
  contractTermsGrp: {
    specialTermsConditions: {
      groupName: '',
      items: [
        { key: 'specialTermsConditions1' },
        { key: 'specialTermsConditions2' },
        { key: 'specialTermsConditions3' },
        { key: 'specialTermsConditions4' },
        { key: 'specialTermsConditions5' },
        { key: 'specialTermsConditions6' },
        { key: 'specialTermsConditions7' },
        { key: 'specialTermsConditions8' },
        { key: 'specialTermsConditions9' },
        { key: 'specialTermsConditions10' },
      ],
    },
  },
  // transferTermsGrp ----------------------------
  transferTermsGrp: {
    shipmentRemarks: {
      groupName: '',
      items: [
        { key: 'shipmentRemarks1' },
        { key: 'shipmentRemarks2' },
        { key: 'shipmentRemarks3' },
      ],
    },
  },
  // inspectionTermsGrp ----------------------------
  inspectionTermsGrp: {
    inspectionTerms: {
      groupName: '',
      items: [
        { key: 'inspectionTerms1' },
        { key: 'inspectionTerms2' },
        { key: 'inspectionTerms3' },
      ],
    },
  },
  // commercialInvoiceGrp ----------------------------
  commercialInvoiceGrp: {
    commercialInvoiceRemarks: {
      groupName: '',
      items: [
        { key: 'commercialInvoiceRemarks1' },
        { key: 'commercialInvoiceRemarks2' },
        { key: 'commercialInvoiceRemarks3' },
        { key: 'commercialInvoiceRemarks4' },
        { key: 'commercialInvoiceRemarks5' },
        { key: 'commercialInvoiceRemarks6' },
        { key: 'commercialInvoiceRemarks7' },
        { key: 'commercialInvoiceRemarks8' },
        { key: 'commercialInvoiceRemarks9' },
        { key: 'commercialInvoiceRemarks10' },
      ],
    },
  },
  // documentsGrp ----------------------------
  documentsGrp: {
    documentName: {
      groupName: '',
      groupButton: true,
      items: [
        { key: 'documentName1' },
        { key: 'documentName2' },
        { key: 'documentName3' },
        { key: 'documentName4' },
        { key: 'documentName5' },
        { key: 'documentName6' },
        { key: 'documentName7' },
        { key: 'documentName8' },
        { key: 'documentName9' },
        { key: 'documentName10' },
      ],
    },
    documentNo: {
      groupName: 'documentName',
      items: [
        { key: 'documentNo1' },
        { key: 'documentNo2' },
        { key: 'documentNo3' },
        { key: 'documentNo4' },
        { key: 'documentNo5' },
        { key: 'documentNo6' },
        { key: 'documentNo7' },
        { key: 'documentNo8' },
        { key: 'documentNo9' },
        { key: 'documentNo10' },
      ],
    },
    documentDate: {
      groupName: 'documentName',
      items: [
        { key: 'documentDate1' },
        { key: 'documentDate2' },
        { key: 'documentDate3' },
        { key: 'documentDate4' },
        { key: 'documentDate5' },
        { key: 'documentDate6' },
        { key: 'documentDate7' },
        { key: 'documentDate8' },
        { key: 'documentDate9' },
        { key: 'documentDate10' },
      ],
    },
    documentBody: {
      groupName: 'documentName',
      items: [
        { key: 'documentBody1' },
        { key: 'documentBody2' },
        { key: 'documentBody3' },
        { key: 'documentBody4' },
        { key: 'documentBody5' },
        { key: 'documentBody6' },
        { key: 'documentBody7' },
        { key: 'documentBody8' },
        { key: 'documentBody9' },
        { key: 'documentBody10' },
      ],
    },
    documentRemarks1: {
      groupName: '',
      nestGroupName: 'documentName',
      items: [
        { key: 'documentRemarks1_1' },
        { key: 'documentRemarks1_2' },
        { key: 'documentRemarks1_3' },
        { key: 'documentRemarks1_4' },
        { key: 'documentRemarks1_5' },
        { key: 'documentRemarks1_6' },
        { key: 'documentRemarks1_7' },
        { key: 'documentRemarks1_8' },
        { key: 'documentRemarks1_9' },
        { key: 'documentRemarks1_10' },
      ],
    },
    documentRemarks2: {
      groupName: '',
      nestGroupName: 'documentName',
      items: [
        { key: 'documentRemarks2_1' },
        { key: 'documentRemarks2_2' },
        { key: 'documentRemarks2_3' },
        { key: 'documentRemarks2_4' },
        { key: 'documentRemarks2_5' },
        { key: 'documentRemarks2_6' },
        { key: 'documentRemarks2_7' },
        { key: 'documentRemarks2_8' },
        { key: 'documentRemarks2_9' },
        { key: 'documentRemarks2_10' },
      ],
    },
    documentRemarks3: {
      groupName: '',
      nestGroupName: 'documentName',
      items: [
        { key: 'documentRemarks3_1' },
        { key: 'documentRemarks3_2' },
        { key: 'documentRemarks3_3' },
        { key: 'documentRemarks3_4' },
        { key: 'documentRemarks3_5' },
        { key: 'documentRemarks3_6' },
        { key: 'documentRemarks3_7' },
        { key: 'documentRemarks3_8' },
        { key: 'documentRemarks3_9' },
        { key: 'documentRemarks3_10' },
      ],
    },
    documentRemarks4: {
      groupName: '',
      nestGroupName: 'documentName',
      items: [
        { key: 'documentRemarks4_1' },
        { key: 'documentRemarks4_2' },
        { key: 'documentRemarks4_3' },
        { key: 'documentRemarks4_4' },
        { key: 'documentRemarks4_5' },
        { key: 'documentRemarks4_6' },
        { key: 'documentRemarks4_7' },
        { key: 'documentRemarks4_8' },
        { key: 'documentRemarks4_9' },
        { key: 'documentRemarks4_10' },
      ],
    },
    documentRemarks5: {
      groupName: '',
      nestGroupName: 'documentName',
      items: [
        { key: 'documentRemarks5_1' },
        { key: 'documentRemarks5_2' },
        { key: 'documentRemarks5_3' },
        { key: 'documentRemarks5_4' },
        { key: 'documentRemarks5_5' },
        { key: 'documentRemarks5_6' },
        { key: 'documentRemarks5_7' },
        { key: 'documentRemarks5_8' },
        { key: 'documentRemarks5_9' },
        { key: 'documentRemarks5_10' },
      ],
    },
    documentRemarks6: {
      groupName: '',
      nestGroupName: 'documentName',
      items: [
        { key: 'documentRemarks6_1' },
        { key: 'documentRemarks6_2' },
        { key: 'documentRemarks6_3' },
        { key: 'documentRemarks6_4' },
        { key: 'documentRemarks6_5' },
        { key: 'documentRemarks6_6' },
        { key: 'documentRemarks6_7' },
        { key: 'documentRemarks6_8' },
        { key: 'documentRemarks6_9' },
        { key: 'documentRemarks6_10' },
      ],
    },
    documentRemarks7: {
      groupName: '',
      nestGroupName: 'documentName',
      items: [
        { key: 'documentRemarks7_1' },
        { key: 'documentRemarks7_2' },
        { key: 'documentRemarks7_3' },
        { key: 'documentRemarks7_4' },
        { key: 'documentRemarks7_5' },
        { key: 'documentRemarks7_6' },
        { key: 'documentRemarks7_7' },
        { key: 'documentRemarks7_8' },
        { key: 'documentRemarks7_9' },
        { key: 'documentRemarks7_10' },
      ],
    },
    documentRemarks8: {
      groupName: '',
      nestGroupName: 'documentName',
      items: [
        { key: 'documentRemarks8_1' },
        { key: 'documentRemarks8_2' },
        { key: 'documentRemarks8_3' },
        { key: 'documentRemarks8_4' },
        { key: 'documentRemarks8_5' },
        { key: 'documentRemarks8_6' },
        { key: 'documentRemarks8_7' },
        { key: 'documentRemarks8_8' },
        { key: 'documentRemarks8_9' },
        { key: 'documentRemarks8_10' },
      ],
    },
    documentRemarks9: {
      groupName: '',
      nestGroupName: 'documentName',
      items: [
        { key: 'documentRemarks9_1' },
        { key: 'documentRemarks9_2' },
        { key: 'documentRemarks9_3' },
        { key: 'documentRemarks9_4' },
        { key: 'documentRemarks9_5' },
        { key: 'documentRemarks9_6' },
        { key: 'documentRemarks9_7' },
        { key: 'documentRemarks9_8' },
        { key: 'documentRemarks9_9' },
        { key: 'documentRemarks9_10' },
      ],
    },
    documentRemarks10: {
      groupName: '',
      nestGroupName: 'documentName',
      items: [
        { key: 'documentRemarks10_1' },
        { key: 'documentRemarks10_2' },
        { key: 'documentRemarks10_3' },
        { key: 'documentRemarks10_4' },
        { key: 'documentRemarks10_5' },
        { key: 'documentRemarks10_6' },
        { key: 'documentRemarks10_7' },
        { key: 'documentRemarks10_8' },
        { key: 'documentRemarks10_9' },
        { key: 'documentRemarks10_10' },
      ],
    },
  },
  // containerInfoGrp ----------------------------
  containerInfoGrp: {
    sealNo: {
      groupName: '',
      items: [
        { key: 'sealNo1' },
        { key: 'sealNo2' },
        { key: 'sealNo3' },
        { key: 'sealNo4' },
        { key: 'sealNo5' },
        { key: 'sealNo6' },
      ],
    },
    sealingParty: {
      groupName: 'sealNo',
      items: [
        { key: 'sealingParty1' },
        { key: 'sealingParty2' },
        { key: 'sealingParty3' },
        { key: 'sealingParty4' },
        { key: 'sealingParty5' },
        { key: 'sealingParty6' },
      ],
    },
  },
  // logisticsViaGrp NOTE: 未使用のためコメントアウト ----------------------------
  // logisticsViaGrp: {
  //   viaId: {
  //     groupName: 'viaCountryName',
  //     items: [
  //       { key: 'viaId1' },
  //       { key: 'viaId2' },
  //       { key: 'viaId3' },
  //       { key: 'viaId4' },
  //       { key: 'viaId5' },
  //     ],
  //   },
  //   viaName: {
  //     groupName: 'viaCountryName',
  //     items: [
  //       { key: 'viaName1' },
  //       { key: 'viaName2' },
  //       { key: 'viaName3' },
  //       { key: 'viaName4' },
  //       { key: 'viaName5' },
  //     ],
  //   },
  //   viaCountry: {
  //     groupName: 'viaCountryName',
  //     items: [
  //       { key: 'viaCountry1' },
  //       { key: 'viaCountry2' },
  //       { key: 'viaCountry3' },
  //       { key: 'viaCountry4' },
  //       { key: 'viaCountry5' },
  //     ],
  //   },
  //   viaCountryName: {
  //     groupName: '',
  //     items: [
  //       { key: 'viaCountryName1' },
  //       { key: 'viaCountryName2' },
  //       { key: 'viaCountryName3' },
  //       { key: 'viaCountryName4' },
  //       { key: 'viaCountryName5' },
  //     ],
  //   },
  // },
  // airGrp ----------------------------
  airGrp: {
    transportationServiceLevel: {
      groupName: 'departureFlightNo',
      items: [
        { key: 'transportationServiceLevel1' },
        { key: 'transportationServiceLevel2' },
        { key: 'transportationServiceLevel3' },
        { key: 'transportationServiceLevel4' },
        { key: 'transportationServiceLevel5' },
      ],
    },
    houseAwbNo: {
      groupName: 'departureFlightNo',
      items: [
        { key: 'houseAwbNo1' },
        { key: 'houseAwbNo2' },
        { key: 'houseAwbNo3' },
        { key: 'houseAwbNo4' },
        { key: 'houseAwbNo5' },
      ],
    },
    masterAwbNo: {
      groupName: 'departureFlightNo',
      items: [
        { key: 'masterAwbNo1' },
        { key: 'masterAwbNo2' },
        { key: 'masterAwbNo3' },
        { key: 'masterAwbNo4' },
        { key: 'masterAwbNo5' },
      ],
    },
    departureFlightNo: {
      groupName: '',
      groupButton: true,
      items: [
        { key: 'departureFlightNo1' },
        { key: 'departureFlightNo2' },
        { key: 'departureFlightNo3' },
        { key: 'departureFlightNo4' },
        { key: 'departureFlightNo5' },
      ],
    },
    transshipmentFlightNo: {
      groupName: 'departureFlightNo',
      items: [
        { key: 'transshipmentFlightNo1' },
        { key: 'transshipmentFlightNo2' },
        { key: 'transshipmentFlightNo3' },
        { key: 'transshipmentFlightNo4' },
        { key: 'transshipmentFlightNo5' },
      ],
    },
    departureTime: {
      groupName: 'departureFlightNo',
      items: [
        { key: 'departureTime1' },
        { key: 'departureTime2' },
        { key: 'departureTime3' },
        { key: 'departureTime4' },
        { key: 'departureTime5' },
      ],
    },
    transshipmentTime: {
      groupName: 'departureFlightNo',
      items: [
        { key: 'transshipmentTime1' },
        { key: 'transshipmentTime2' },
        { key: 'transshipmentTime3' },
        { key: 'transshipmentTime4' },
        { key: 'transshipmentTime5' },
      ],
    },
    arrivalTime: {
      groupName: 'departureFlightNo',
      items: [
        { key: 'arrivalTime1' },
        { key: 'arrivalTime2' },
        { key: 'arrivalTime3' },
        { key: 'arrivalTime4' },
        { key: 'arrivalTime5' },
      ],
    },
    departureAirportId: {
      groupName: 'departureFlightNo',
      items: [
        { key: 'departureAirportId1' },
        { key: 'departureAirportId2' },
        { key: 'departureAirportId3' },
        { key: 'departureAirportId4' },
        { key: 'departureAirportId5' },
      ],
    },
    departureAirportName: {
      groupName: 'departureFlightNo',
      items: [
        { key: 'departureAirportName1' },
        { key: 'departureAirportName2' },
        { key: 'departureAirportName3' },
        { key: 'departureAirportName4' },
        { key: 'departureAirportName5' },
      ],
    },
    arrivalAirportId: {
      groupName: 'departureFlightNo',
      items: [
        { key: 'arrivalAirportId1' },
        { key: 'arrivalAirportId2' },
        { key: 'arrivalAirportId3' },
        { key: 'arrivalAirportId4' },
        { key: 'arrivalAirportId5' },
      ],
    },
    arrivalAirportName: {
      groupName: 'departureFlightNo',
      items: [
        { key: 'arrivalAirportName1' },
        { key: 'arrivalAirportName2' },
        { key: 'arrivalAirportName3' },
        { key: 'arrivalAirportName4' },
        { key: 'arrivalAirportName5' },
      ],
    },
  },
  // bookingReferenceGrp ----------------------------
  bookingReferenceGrp: {
    referenceType: {
      groupName: '',
      groupButton: true,
      items: [
        { key: 'referenceType1' },
        { key: 'referenceType2' },
        { key: 'referenceType3' },
        { key: 'referenceType4' },
        { key: 'referenceType5' },
        { key: 'referenceType6' },
        { key: 'referenceType7' },
        { key: 'referenceType8' },
        { key: 'referenceType9' },
        { key: 'referenceType10' },
      ],
    },
    referenceNo: {
      groupName: 'referenceType',
      items: [
        { key: 'referenceNo1' },
        { key: 'referenceNo2' },
        { key: 'referenceNo3' },
        { key: 'referenceNo4' },
        { key: 'referenceNo5' },
        { key: 'referenceNo6' },
        { key: 'referenceNo7' },
        { key: 'referenceNo8' },
        { key: 'referenceNo9' },
        { key: 'referenceNo10' },
      ],
    },
    dateOfIssue: {
      groupName: 'referenceType',
      items: [
        { key: 'dateOfIssue1' },
        { key: 'dateOfIssue2' },
        { key: 'dateOfIssue3' },
        { key: 'dateOfIssue4' },
        { key: 'dateOfIssue5' },
        { key: 'dateOfIssue6' },
        { key: 'dateOfIssue7' },
        { key: 'dateOfIssue8' },
        { key: 'dateOfIssue9' },
        { key: 'dateOfIssue10' },
      ],
    },
    expiryDate: {
      groupName: 'referenceType',
      items: [
        { key: 'expiryDate1' },
        { key: 'expiryDate2' },
        { key: 'expiryDate3' },
        { key: 'expiryDate4' },
        { key: 'expiryDate5' },
        { key: 'expiryDate6' },
        { key: 'expiryDate7' },
        { key: 'expiryDate8' },
        { key: 'expiryDate9' },
        { key: 'expiryDate10' },
      ],
    },
  },
  // blRequestGrp ----------------------------
  blRequestGrp: {
    blReferenceType: {
      groupName: 'blReferenceNo',
      items: [
        { key: 'blReferenceType1' },
        { key: 'blReferenceType2' },
        { key: 'blReferenceType3' },
        { key: 'blReferenceType4' },
        { key: 'blReferenceType5' },
        { key: 'blReferenceType6' },
        { key: 'blReferenceType7' },
        { key: 'blReferenceType8' },
        { key: 'blReferenceType9' },
        { key: 'blReferenceType10' },
      ],
    },
    blReferenceNo: {
      groupName: '',
      items: [
        { key: 'blReferenceNo1' },
        { key: 'blReferenceNo2' },
        { key: 'blReferenceNo3' },
        { key: 'blReferenceNo4' },
        { key: 'blReferenceNo5' },
        { key: 'blReferenceNo6' },
        { key: 'blReferenceNo7' },
        { key: 'blReferenceNo8' },
        { key: 'blReferenceNo9' },
        { key: 'blReferenceNo10' },
      ],
    },
  },
  // blSwbAwbTermsGrp ----------------------------
  blSwbAwbTermsGrp: {
    freightCollectPrepaid: {
      groupName: 'chargeType',
      items: [
        { key: 'freightCollectPrepaid1' },
        { key: 'freightCollectPrepaid2' },
        { key: 'freightCollectPrepaid3' },
        { key: 'freightCollectPrepaid4' },
        { key: 'freightCollectPrepaid5' },
        { key: 'freightCollectPrepaid6' },
        { key: 'freightCollectPrepaid7' },
        { key: 'freightCollectPrepaid8' },
        { key: 'freightCollectPrepaid9' },
        { key: 'freightCollectPrepaid10' },
      ],
    },
    chargeType: {
      groupName: '',
      items: [
        { key: 'chargeType1' },
        { key: 'chargeType2' },
        { key: 'chargeType3' },
        { key: 'chargeType4' },
        { key: 'chargeType5' },
        { key: 'chargeType6' },
        { key: 'chargeType7' },
        { key: 'chargeType8' },
        { key: 'chargeType9' },
        { key: 'chargeType10' },
      ],
    },
  },
  // ipreqInformationGrp ----------------------------
  ipreqInformationGrp: {
    ipreqDescriptionOfGoods: {
      groupName: '',
      groupButton: true,
      items: [
        { key: 'ipreqDescriptionOfGoods01' },
        { key: 'ipreqDescriptionOfGoods02' },
        { key: 'ipreqDescriptionOfGoods03' },
        { key: 'ipreqDescriptionOfGoods04' },
        { key: 'ipreqDescriptionOfGoods05' },
        { key: 'ipreqDescriptionOfGoods06' },
        { key: 'ipreqDescriptionOfGoods07' },
        { key: 'ipreqDescriptionOfGoods08' },
        { key: 'ipreqDescriptionOfGoods09' },
        { key: 'ipreqDescriptionOfGoods10' },
      ],
    },
    ipreqQuantity1: {
      groupName: 'ipreqDescriptionOfGoods',
      // nestGroupName: 'ipreqDescriptionOfGoods',
      items: [
        { key: 'ipreqQuantity1_01' },
        { key: 'ipreqQuantity1_02' },
        { key: 'ipreqQuantity1_03' },
        { key: 'ipreqQuantity1_04' },
        { key: 'ipreqQuantity1_05' },
        { key: 'ipreqQuantity1_06' },
        { key: 'ipreqQuantity1_07' },
        { key: 'ipreqQuantity1_08' },
        { key: 'ipreqQuantity1_09' },
        { key: 'ipreqQuantity1_10' },
      ],
    },
    ipreqQuantity2: {
      groupName: 'ipreqDescriptionOfGoods',
      // nestGroupName: 'ipreqDescriptionOfGoods',
      items: [
        { key: 'ipreqQuantity2_01' },
        { key: 'ipreqQuantity2_02' },
        { key: 'ipreqQuantity2_03' },
        { key: 'ipreqQuantity2_04' },
        { key: 'ipreqQuantity2_05' },
        { key: 'ipreqQuantity2_06' },
        { key: 'ipreqQuantity2_07' },
        { key: 'ipreqQuantity2_08' },
        { key: 'ipreqQuantity2_09' },
        { key: 'ipreqQuantity2_10' },
      ],
    },
    ipreqHsCode: {
      groupName: 'ipreqDescriptionOfGoods',
      // nestGroupName: 'ipreqDescriptionOfGoods',
      items: [
        { key: 'ipreqHsCode01' },
        { key: 'ipreqHsCode02' },
        { key: 'ipreqHsCode03' },
        { key: 'ipreqHsCode04' },
        { key: 'ipreqHsCode05' },
        { key: 'ipreqHsCode06' },
        { key: 'ipreqHsCode07' },
        { key: 'ipreqHsCode08' },
        { key: 'ipreqHsCode09' },
        { key: 'ipreqHsCode10' },
      ],
    },
  },
  // ipreqSpecialConditionsGrp ----------------------------
  ipreqSpecialConditionsGrp: {
    ipreqCoInsuranceCompany: {
      groupName: '',
      items: [
        { key: 'ipreqCoInsuranceCompany01' },
        { key: 'ipreqCoInsuranceCompany02' },
        { key: 'ipreqCoInsuranceCompany03' },
        { key: 'ipreqCoInsuranceCompany04' },
        { key: 'ipreqCoInsuranceCompany05' },
        { key: 'ipreqCoInsuranceCompany06' },
        { key: 'ipreqCoInsuranceCompany07' },
      ],
    },
    ipreqCoInsuranceShare: {
      groupName: 'ipreqCoInsuranceCompany',
      items: [
        { key: 'ipreqCoInsuranceShare01' },
        { key: 'ipreqCoInsuranceShare02' },
        { key: 'ipreqCoInsuranceShare03' },
        { key: 'ipreqCoInsuranceShare04' },
        { key: 'ipreqCoInsuranceShare05' },
        { key: 'ipreqCoInsuranceShare06' },
        { key: 'ipreqCoInsuranceShare07' },
      ],
    },
  },
  // cargoTrackingGrp ----------------------------
  cargoTrackingGrp: {
    exCargoTrackingEmailAddress: {
      groupName: '',
      items: [
        { key: 'exCargoTrackingEmailAddress1'},
        { key: 'exCargoTrackingEmailAddress2'},
        { key: 'exCargoTrackingEmailAddress3'},
        { key: 'exCargoTrackingEmailAddress4'},
        { key: 'exCargoTrackingEmailAddress5'}
      ],
    },
    imCargoTrackingEmailAddress: {
      groupName: '',
      items: [
        { key: 'imCargoTrackingEmailAddress1'},
        { key: 'imCargoTrackingEmailAddress2'},
        { key: 'imCargoTrackingEmailAddress3'},
        { key: 'imCargoTrackingEmailAddress4'},
        { key: 'imCargoTrackingEmailAddress5'}
      ],
    },
  },
  // // AddressがあるGrp ↓↓ NOTE: 未使用のため以下全てコメントアウト ===========================================
  // // sellerGrp ----------------------------
  // sellerGrp: {
  //   sellerAddress: {
  //     groupName: '',
  //     items: [
  //       { key: 'sellerAddress1' },
  //       { key: 'sellerAddress2' },
  //       { key: 'sellerAddress3' },
  //     ],
  //   },
  // },
  // // buyerGrp ----------------------------
  // buyerGrp: {
  //   buyerAddress: {
  //     groupName: '',
  //     items: [
  //       { key: 'buyerAddress1' },
  //       { key: 'buyerAddress2' },
  //       { key: 'buyerAddress3' },
  //     ],
  //   },
  // },
  // // makerGrp ----------------------------
  // makerGrp: {
  //   makerAddress: {
  //     groupName: '',
  //     items: [
  //       { key: 'makerAddress1' },
  //       { key: 'makerAddress2' },
  //       { key: 'makerAddress3' },
  //     ],
  //   },
  // },
  // // vendorGrp ----------------------------
  // vendorGrp: {
  //   vendorAddress: {
  //     groupName: '',
  //     items: [
  //       { key: 'vendorAddress1' },
  //       { key: 'vendorAddress2' },
  //       { key: 'vendorAddress3' },
  //     ],
  //   },
  // },
  // // endUserGrp ----------------------------
  // endUserGrp: {
  //   endUserAddress: {
  //     groupName: '',
  //     items: [
  //       { key: 'endUserAddress1' },
  //       { key: 'endUserAddress2' },
  //       { key: 'endUserAddress3' },
  //     ],
  //   },
  // },
  // // customsAgentGrp ----------------------------
  // customsAgentGrp: {
  //   customsAgentAddress: {
  //     groupName: '',
  //     items: [
  //       { key: 'customsAgentAddress1' },
  //       { key: 'customsAgentAddress2' },
  //       { key: 'customsAgentAddress3' },
  //     ],
  //   },
  // },
  // // forwarderGrp ----------------------------
  // forwarderGrp: {
  //   forwarderAddress: {
  //     groupName: '',
  //     items: [
  //       { key: 'forwarderAddress1' },
  //       { key: 'forwarderAddress2' },
  //       { key: 'forwarderAddress3' },
  //     ],
  //   },
  // },
  // // forwarderShipmentGrp ----------------------------
  // forwarderShipmentGrp: {
  //   forwarderAddress: {
  //     groupName: '',
  //     items: [
  //       { key: 'forwarderContainerAddress1' },
  //       { key: 'forwarderContainerAddress2' },
  //       { key: 'forwarderContainerAddress3' },
  //     ],
  //   },
  // },
  // // forwarderNvoccGrp ----------------------------
  // forwarderNvoccGrp: {
  //   forwarderAddress: {
  //     groupName: '',
  //     items: [
  //       { key: 'forwarderContainerAddress1' },
  //       { key: 'forwarderContainerAddress2' },
  //       { key: 'forwarderContainerAddress3' },
  //     ],
  //   },
  // },
  // // fwdImCntGrp ----------------------------
  // fwdImCntGrp: {
  //   forwarderAddress: {
  //     groupName: '',
  //     items: [
  //       { key: 'forwarderContainerAddress1' },
  //       { key: 'forwarderContainerAddress2' },
  //       { key: 'forwarderContainerAddress3' },
  //     ],
  //   },
  // },
  // // impForwarderNvoccGrp ----------------------------
  // impForwarderNvoccGrp: {
  //   forwarderAddress: {
  //     groupName: '',
  //     items: [
  //       { key: 'forwarderContainerAddress1' },
  //       { key: 'forwarderContainerAddress2' },
  //       { key: 'forwarderContainerAddress3' },
  //     ],
  //   },
  // },
  // // forwarderIpGrp ----------------------------
  // forwarderIpGrp: {
  //   forwarderIpAddress: {
  //     groupName: '',
  //     items: [
  //       { key: 'forwarderIpAddress1' },
  //       { key: 'forwarderIpAddress2' },
  //       { key: 'forwarderIpAddress3' },
  //     ],
  //   },
  //   forwarderDocsAddress: {
  //     groupName: '',
  //     items: [
  //       { key: 'forwarderDocsAddress1' },
  //       { key: 'forwarderDocsAddress2' },
  //       { key: 'forwarderDocsAddress3' },
  //     ],
  //   },
  // },
  // // airGrp ----------------------------
  // // airGrp: {
  // //   airfreightForwarderAddress: {
  // //     groupName: '',
  // //     items: [
  // //       { key: 'airfreightForwarderAddress1' },
  // //       { key: 'airfreightForwarderAddress2' },
  // //       { key: 'airfreightForwarderAddress3' },
  // //     ],
  // //   },
  // // },
  // // airfreightGrp ----------------------------
  // airfreightGrp: {
  //   airfreightForwarderAddress: {
  //     groupName: '',
  //     items: [
  //       { key: 'airfreightForwarderAddress1' },
  //       { key: 'airfreightForwarderAddress2' },
  //       { key: 'airfreightForwarderAddress3' },
  //     ],
  //   },
  // },
  // // charterGrp ----------------------------
  // charterGrp: {
  //   charteringBrokerAddress: {
  //     groupName: '',
  //     items: [
  //       { key: 'charteringBrokerAddress1' },
  //       { key: 'charteringBrokerAddress2' },
  //       { key: 'charteringBrokerAddress3' },
  //     ],
  //   },
  // },
  // // charteringBrokerGrp ----------------------------
  // charteringBrokerGrp: {
  //   charteringBrokerAddress: {
  //     groupName: '',
  //     items: [
  //       { key: 'charteringBrokerAddress1' },
  //       { key: 'charteringBrokerAddress2' },
  //       { key: 'charteringBrokerAddress3' },
  //     ],
  //   },
  // },
  // // shipperGrp ----------------------------
  // shipperGrp: {
  //   shipperAddress: {
  //     groupName: '',
  //     items: [
  //       { key: 'shipperAddress1' },
  //       { key: 'shipperAddress2' },
  //       { key: 'shipperAddress3' },
  //     ],
  //   },
  // },
  // // consigneeGrp ----------------------------
  // consigneeGrp: {
  //   consigneeAddress: {
  //     groupName: '',
  //     items: [
  //       { key: 'consigneeAddress1' },
  //       { key: 'consigneeAddress2' },
  //       { key: 'consigneeAddress3' },
  //     ],
  //   },
  // },
  // // notify1Grp ----------------------------
  // notify1Grp: {
  //   notifyAddress: {
  //     groupName: '',
  //     items: [
  //       { key: 'notifyAddress1' },
  //       { key: 'notifyAddress2' },
  //       { key: 'notifyAddress3' },
  //     ],
  //   },
  // },
  // // notify2Grp ----------------------------
  // notify2Grp: {
  //   notifyAddress2: {
  //     groupName: '',
  //     items: [
  //       { key: 'notifyAddress2_1' },
  //       { key: 'notifyAddress2_2' },
  //       { key: 'notifyAddress2_3' },
  //     ],
  //   },
  // },
  // // carrierGrp ----------------------------
  // carrierGrp: {
  //   carrierAddress: {
  //     groupName: '',
  //     items: [
  //       { key: 'carrierAddress1' },
  //       { key: 'carrierAddress2' },
  //       { key: 'carrierAddress3' },
  //     ],
  //   },
  // },
  // // salesAgentGrp ----------------------------
  // salesAgentGrp: {
  //   salesAgentAddress: {
  //     groupName: '',
  //     items: [
  //       { key: 'salesAgentAddress1' },
  //       { key: 'salesAgentAddress2' },
  //       { key: 'salesAgentAddress3' },
  //     ],
  //   },
  // },
  // // purchaseAgentGrp ----------------------------
  // purchaseAgentGrp: {
  //   purchaseAgentAddress: {
  //     groupName: '',
  //     items: [
  //       { key: 'purchaseAgentAddress1' },
  //       { key: 'purchaseAgentAddress2' },
  //       { key: 'purchaseAgentAddress3' },
  //     ],
  //   },
  // },
}
